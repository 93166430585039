import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import { useTranslation } from 'react-i18next';

function UserProfile() {
  const [basicInfo, setBasicInfo] = useState({
    username: '',
    email: '',
    newEmail: ''
  });
  const [originalUsername, setOriginalUsername] = useState('');
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [basicInfoMessage, setBasicInfoMessage] = useState({ type: '', content: '' });
  const [passwordMessage, setPasswordMessage] = useState({ type: '', content: '' });
  const [emailMessage, setEmailMessage] = useState({ type: '', content: '' });
  const [isPasswordSectionVisible, setIsPasswordSectionVisible] = useState(false);
  const [isEmailChangeVisible, setIsEmailChangeVisible] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await axiosInstance.get(`/api/users/profile/${userId}`);
        setBasicInfo(prevState => ({
          ...prevState,
          username: response.data.username,
          email: response.data.email,
          newEmail: ''
        }));
        setOriginalUsername(response.data.username);
      } catch (err) {
        setBasicInfoMessage({
          type: 'error',
          content: t(err.response?.data?.msg || 'serverError')
        });
      }
    };

    fetchUserData();
  }, [t]);

  const handleBasicInfoChange = (e) => {
    setBasicInfo({ ...basicInfo, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleBasicInfoSubmit = async (e) => {
    e.preventDefault();
    
    if (basicInfo.username === originalUsername) {
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      const updateData = {
        username: basicInfo.username,
      };

      const response = await axiosInstance.put(`/api/users/profile/${userId}`, updateData);
      setBasicInfoMessage({ type: 'success', content: t('profileUpdateSuccess') });
      localStorage.setItem('username', response.data.username);
      setOriginalUsername(response.data.username);
      window.dispatchEvent(new Event('storage'));
    } catch (err) {
      if (err.response?.data?.msg === 'usernameAlreadyExists') {
        setBasicInfoMessage({
          type: 'error',
          content: t('usernameAlreadyTaken', { username: basicInfo.username })
        });
        // Revert username to original
        setBasicInfo(prev => ({
          ...prev,
          username: originalUsername
        }));
      } else {
        setBasicInfoMessage({
          type: 'error',
          content: t(err.response?.data?.msg || 'serverError')
        });
      }
    }
  };

  const handleEmailChangeSubmit = async (e) => {
    e.preventDefault();
    try {
      const userId = localStorage.getItem('userId');
      const response = await axiosInstance.post(`/api/users/change-email/${userId}`, {
        newEmail: basicInfo.newEmail
      });
      
      setEmailMessage({ type: 'success', content: t('emailVerificationSent') });
      setIsEmailChangeVisible(false);
      setBasicInfo(prev => ({ ...prev, newEmail: '' }));
    } catch (err) {
      setEmailMessage({
        type: 'error',
        content: t(err.response?.data?.msg || 'serverError')
      });
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      setPasswordMessage({ type: 'error', content: t('passwordsDontMatch') });
      return;
    }

    try {
      const userId = localStorage.getItem('userId');
      await axiosInstance.put(`/api/users/profile/${userId}`, {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword
      });
      
      setPasswordMessage({ type: 'success', content: t('passwordUpdateSuccess') });
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      });
    } catch (err) {
      setPasswordMessage({
        type: 'error',
        content: t(err.response?.data?.msg || 'serverError')
      });
    }
  };

  return (
    <div className="profile-container">
      <h2>{t('userProfile')}</h2>
      
      <div className="profile-section">
        {basicInfoMessage.content && (
          <div className={`message ${basicInfoMessage.type}`}>
            {basicInfoMessage.content}
          </div>
        )}
        {emailMessage.content && (
          <div className={`message ${emailMessage.type}`}>
            {emailMessage.content}
          </div>
        )}
        <h3>{t('basicInformation')}</h3>
        <form onSubmit={handleBasicInfoSubmit} className="profile-form">
          <div className="form-group">
            <label htmlFor="username">{t('username')}</label>
            <input
              type="text"
              id="username"
              name="username"
              value={basicInfo.username}
              onChange={handleBasicInfoChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">{t('currentEmail')}</label>
            <input
              type="email"
              id="email"
              value={basicInfo.email}
              disabled
              className="disabled-input"
            />
            <button 
              type="button" 
              className="secondary-button"
              onClick={() => setIsEmailChangeVisible(!isEmailChangeVisible)}
            >
              {t('changeEmail')}
            </button>
          </div>

          <button type="submit" className="submit-button">
            {t('saveBasicInfo')}
          </button>
        </form>

        {isEmailChangeVisible && (
          <form onSubmit={handleEmailChangeSubmit} className="profile-form email-change-form">
            <div className="form-group">
              <label htmlFor="newEmail">{t('newEmail')}</label>
              <input
                type="email"
                id="newEmail"
                name="newEmail"
                value={basicInfo.newEmail}
                onChange={handleBasicInfoChange}
                required
              />
            </div>
            <button type="submit" className="submit-button">
              {t('sendVerification')}
            </button>
          </form>
        )}
      </div>

      <div className="profile-section password-section">
        <div className="password-header" onClick={() => setIsPasswordSectionVisible(!isPasswordSectionVisible)}>
          <h3>{t('changePassword')}</h3>
          <span className="toggle-icon">{isPasswordSectionVisible ? '−' : '+'}</span>
        </div>

        {isPasswordSectionVisible && (
          <>
            {passwordMessage.content && (
              <div className={`message ${passwordMessage.type}`}>
                {passwordMessage.content}
              </div>
            )}
            <form onSubmit={handlePasswordSubmit} className="profile-form">
              <div className="form-group">
                <label htmlFor="currentPassword">{t('currentPassword')}</label>
                <input
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  value={passwordData.currentPassword}
                  onChange={handlePasswordChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="newPassword">{t('newPassword')}</label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="confirmNewPassword">{t('confirmNewPassword')}</label>
                <input
                  type="password"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  value={passwordData.confirmNewPassword}
                  onChange={handlePasswordChange}
                  required
                />
              </div>

              <button type="submit" className="submit-button">
                {t('updatePassword')}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default UserProfile; 