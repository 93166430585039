import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          home: 'Home',
          createEvent: 'Create Event',
          adminPanel: 'Admin Panel',
          register: 'Register',
          login: 'Login',
          logout: 'Logout',
          pickleballEvents: 'Pickleball Events',
          username: 'Username',
          email: 'Email',
          role: 'Role',
          actions: 'Actions',
          delete: 'Delete',
          title: 'Title',
          time: 'Time',
          timeWithDay: 'Time ({{day}})',
          location: 'Location',
          action: 'Action',
          confirmDelete: 'Confirm Delete',
          confirmDeleteUser: 'Are you sure you want to delete this user?',
          confirmDeleteEvent: 'Are you sure you want to delete this event?',
          error: 'Error',
          errorDeletingUser: 'An error occurred while deleting the user.',
          errorDeletingEvent: 'An error occurred while deleting the event.',
          users: 'Users',
          events: 'Events',
          user: 'User',
          admin: 'Admin',
          password: 'Password',
          confirmPassword: 'Confirm Password',
          createAccount: 'Create Account',
          alreadyHaveAccount: 'Already have an account? Login',
          dontHaveAccount: "Don't have an account? Register",
          eventDetails: 'Event Details',
          participants: 'Participants',
          maxParticipants: 'Max Participants',
          registerForEvent: 'Register for Event',
          unregisterFromEvent: 'Unregister from Event',
          registrationOpens: 'Registration opens',
          eventIsFull: 'Event is full',
          eventName: 'Event Name',
          eventTime: 'Event Time',
          limitation: 'Limitation',
          registrationStartTime: 'Registration Start Time',
          registrationIsOpen: 'Registration is open!',
          registrationOpensIn: 'Registration opens in {{days}}d {{hours}}h {{minutes}}m {{seconds}}s',
          registrationStatus: 'Registration Status',
          eventFullWaitlisted: 'The event is full. You have been added to the waitlist.',
          registrationSuccessful: 'You have successfully registered for the event.',
          registrationError: 'An error occurred while registering.',
          cancellationConfirmed: 'Cancellation Confirmed',
          registrationCancelled: 'Your registration has been cancelled.',
          cancellationError: 'An error occurred while cancelling registration.',
          loading: 'Loading...',
          waitlist: 'Waitlist',
          registeredParticipants: 'Registered Participants',
          cancelRegistration: 'Cancel Registration',
          cancelWaitlist: 'Cancel Waitlist',
          processing: 'Processing...',
          manager: 'Manager',
          emailVerification: 'Email Verification',
          passwordsDontMatch: "Passwords don't match",
          pleaseVerifyEmail: 'Please verify your email before logging in, if you didn\'t receive the email, please check your spam folder',
          forgotPassword: 'Forgot Password',
          sendResetLink: 'Send Reset Link',
          resetPassword: 'Reset Password',
          newPassword: 'New Password',
          invalidCredentials: 'Invalid email or password',
          serverError: 'Server error',
          userAlreadyExists: 'User already exists',
          invalidVerificationToken: 'Invalid verification token',
          emailVerifiedSuccessfully: 'Email verified successfully',
          userNotFound: 'User not found',
          passwordResetEmailSent: 'Password reset email sent',
          invalidOrExpiredToken: 'Invalid or expired token',
          passwordResetSuccessfully: 'Password reset successfully',
          enterYourName: 'Enter your name',
          visitor: 'Visitor',
          registrationCancelledByManager: 'Registration cancelled by manager.',
          confirmCancellation: 'Confirm Cancellation',
          confirmCancellationMessage: 'Are you sure you want to cancel the registration for {{name}}?',
          confirm: 'Confirm',
          cancel: 'Cancel',
          enableImmediateRegistration: 'Enable immediate registration',
          close: 'Close',
          enterAdditionalName: 'Enter additional participant name',
          multipleRegistrationSuccess: 'Successfully registered multiple participants',
          someParticipantsWaitlisted: 'Some participants have been registered, others have been added to the waitlist.',
          eventInfo: 'Event Information',
          enterEventInfo: 'Enter event details, rules, or any additional information...',
          noEventInfo: 'No event information provided',
          shareEvent: 'Share Event',
          checkOutEvent: '\nTime: {{time}}\nRegistration Start Time: {{registrationTime}}\nLocation: {{location}}\nCapacity: {{participants}}',
          linkCopied: 'Link copied to clipboard',
          success: 'Success',
          optional: 'Optional',
          "User registered. Please check your email to verify your account.": "User registered. Please check your email to verify your account.",
          "Server error": "Server error",
          "is already registered": "is already registered",
          "At least one name is required": "At least one name is required",
          "Error sharing:": "Error sharing:",
          "Error copying to clipboard:": "Error copying to clipboard:",
          "Something went wrong. Please try refreshing the page": "Something went wrong. Please try refreshing the page",
          resendVerification: "Resend verification email",
          verificationEmailResent: "Verification email has been resent. Please check your inbox.",
          alreadyVerified: "This account is already verified.",
          errorSendingEmail: "Error sending verification email.",
          contactUs: "Contact Us",
          contactDescription: "Have a question or feedback? We'd love to hear from you. Fill out the form below and we'll get back to you as soon as possible.",
          message: "Message",
          send: "Send Message",
          sending: "Sending...",
          messageSent: "Thank you! Your message has been sent successfully.",
          errorSendingMessage: "Sorry, there was an error sending your message. Please try again.",
          invalidEmail: "Please enter a valid email address",
          messageRequired: "Message must be at least 10 characters long",
          tooManyRequests: "Too many messages sent. Please try again later.",
          emailPlaceholder: "Enter your email address",
          messagePlaceholder: "Type your message here...",
          responseTime: "We usually respond within 24 hours.",
          "Event not found": "Event not found",
          "Registration has not started yet": "Registration has not started yet",
          "User not found": "User not found",
          "Invalid token": "Invalid token",
          "Server Error": "Server Error",
          "alreadyRegisteredForEvent": "is already registered for this event",
          "emailAlreadyExists": "This email is already registered",
          "usernameAlreadyExists": "This username is already taken",
          userProfile: 'User Profile',
          changePassword: 'Change Password',
          currentPassword: 'Current Password',
          confirmNewPassword: 'Confirm New Password',
          saveChanges: 'Save Changes',
          profileUpdateSuccess: 'Profile updated successfully',
          invalidCurrentPassword: 'Current password is incorrect',
          profile: 'Profile',
          basicInformation: 'Basic Information',
          saveBasicInfo: 'Save Changes',
          updatePassword: 'Update Password',
          passwordUpdateSuccess: 'Password updated successfully',
          currentEmail: 'Current Email',
          newEmail: 'New Email',
          changeEmail: 'Change Email',
          sendVerification: 'Send Verification Email',
          emailVerificationSent: 'Verification email sent. Please check your inbox.',
          emailChangeSuccess: 'Email updated successfully',
          usernameAlreadyTaken: 'This username "{{username}}" is already taken',
        },
      },
      zh: {
        translation: {
          home: '首頁',
          createEvent: '建立活動',
          adminPanel: '管理員面板',
          register: '註冊',
          login: '登錄',
          logout: '登出',
          pickleballEvents: '匹克球活動',
          username: '用戶名',
          email: '電子郵件',
          role: '角色',
          actions: '操作',
          delete: '刪除',
          title: '標題',
          time: '時間',
          timeWithDay: '時間 ({{day}})',
          location: '地點',
          action: '操作',
          confirmDelete: '確認刪除',
          confirmDeleteUser: '您確定要刪除此用戶嗎？',
          confirmDeleteEvent: '您確定要刪除此活動嗎？',
          error: '錯誤',
          errorDeletingUser: '刪除用戶時發生錯誤。',
          errorDeletingEvent: '刪除活動時發生錯誤。',
          users: '用戶',
          events: '活動',
          user: '用戶',
          admin: '系統管理員',
          password: '密碼',
          confirmPassword: '確認密碼',
          createAccount: '創建帳戶',
          alreadyHaveAccount: '已有帳戶？登錄',
          dontHaveAccount: '沒有帳戶？註冊',
          eventDetails: '活動詳情',
          participants: '參與者',
          maxParticipants: '最大參與人數',
          registerForEvent: '報名參加',
          unregisterFromEvent: '取消報名',
          registrationOpens: '報名開始時間',
          eventIsFull: '活動已滿',
          eventName: '活動名稱',
          eventTime: '活動時間',
          limitation: '限制',
          registrationStartTime: '報名開始時間',
          registrationIsOpen: '報名現已開放！',
          registrationOpensIn: '報名將在 {{days}}天 {{hours}}小時 {{minutes}}分鐘 {{seconds}}秒後開始',
          registrationStatus: '報名狀態',
          eventFullWaitlisted: '活動已滿。您已被加入候補名單。',
          registrationSuccessful: '您已成功報名參加活動。',
          registrationError: '報名時發生錯誤。',
          cancellationConfirmed: '取消確認',
          registrationCancelled: '您的報名已被取消。',
          cancellationError: '取消報名時發生錯誤。',
          loading: '加載中...',
          waitlist: '候補名單',
          registeredParticipants: '已報名參與者',
          cancelRegistration: '取消報名',
          cancelWaitlist: '取消候補',
          processing: '處理中...',
          manager: '管理員',
          emailVerification: '驗證Email',
          passwordsDontMatch: "密碼不符合",
          pleaseVerifyEmail: '請在登入前驗證您的Email，若無收到郵件，請檢查垃圾郵件信箱',
          forgotPassword: '忘記密碼',
          sendResetLink: '發送重置鏈接',
          resetPassword: '重置密碼',
          newPassword: '新密碼',
          invalidCredentials: '無效的Email或密碼',
          serverError: '伺服器錯誤',
          userAlreadyExists: '用戶已存在',
          invalidVerificationToken: '無效的驗證令牌',
          emailVerifiedSuccessfully: '郵箱驗證成功',
          userNotFound: '未找到用戶',
          passwordResetEmailSent: '密碼重置郵件已發送',
          invalidOrExpiredToken: '無效或過期的令牌',
          passwordResetSuccessfully: '密碼重置成功',
          enterYourName: '請輸入您的名稱',
          visitor: '訪客',
          registrationCancelledByManager: '報名已被管理員取消。',
          confirmCancellation: '確認取消',
          confirmCancellationMessage: '您確定要取消 {{name}} 的報名嗎？',
          confirm: '確認',
          cancel: '取消',
          enableImmediateRegistration: '立即開始報名',
          close: '關閉',
          enterAdditionalName: '輸入其他參與者姓名',
          multipleRegistrationSuccess: '成功註冊多位參與者',
          someParticipantsWaitlisted: '部分參與者已註冊，其他人已加入候補名單。',
          eventInfo: '活動資訊',
          enterEventInfo: '輸入活動詳情、規則或任何額外資訊...',
          noEventInfo: '未提供活動資訊',
          shareEvent: '分享活動',
          checkOutEvent: '\n時間：{{time}}\n報名開始時間：{{registrationTime}}\n地點：{{location}}\n開放人數：{{participants}}',
          linkCopied: '鏈接已複製到剪貼板',
          success: '成功',
          optional: '選填',
          "Limitation must be greater than 0": "限制人數必須大於0",
          "Event time cannot be before current time": "活動時間不能早於當前時間",
          "Registration time cannot be after event time": "報名時間不能晚於活動時間",
          "Invalid email or password": "無效的電子郵件或密碼",
          "Server error": "伺服器錯誤",
          "User already exists": "用戶已存在",
          "Invalid verification token": "無效的驗證令牌",
          "User not found": "找不到用戶",
          "Invalid or expired token": "無效或過期的令牌",
          "Passwords don't match": "密碼不匹配",
          "Something went wrong": "發生錯誤",
          "Please try refreshing the page": "請嘗試刷新頁面",
          "Error occurred while registering": "註冊時發生錯誤",
          "Error occurred while cancelling registration": "取消註冊時發生錯誤",
          "Error occurred while deleting user": "刪除用戶時發生錯誤",
          "Error occurred while deleting event": "刪除活動時發生錯誤",
          "Failed to delete user": "刪除用戶失敗",
          "Failed to delete event": "刪除活動失敗",
          "User registered. Please check your email to verify your account.": "註冊成功。請檢查您的電子郵件以驗證帳戶。",
          "is already registered": "已經註冊",
          "At least one name is required": "至少需要輸入一個名字",
          "Error sharing:": "分享時發生錯誤：",
          "Error copying to clipboard:": "複製到剪貼板時發生錯誤：",
          "Something went wrong. Please try refreshing the page": "發生錯誤。請嘗試刷新頁面",
          resendVerification: "重新發送驗證郵件",
          verificationEmailResent: "驗證郵件已重新發送。請檢查您的收件箱。",
          alreadyVerified: "此帳戶已驗證。",
          errorSendingEmail: "發送驗證郵件時出錯。",
          contactUs: "聯絡我們",
          contactDescription: "有問題或反饋嗎？我們很樂意聽取您的意見。請填寫以下表格，我們會盡快回覆您。",
          message: "訊息",
          send: "發送訊息",
          sending: "發送中...",
          messageSent: "謝謝！您的訊息已成功發送。",
          errorSendingMessage: "抱歉，發送訊息時出現錯誤。請重試。",
          invalidEmail: "請輸入有效的電子郵件地址",
          messageRequired: "訊息必須少包含10個字符",
          tooManyRequests: "發送訊息過於頻繁。請稍後再試。",
          emailPlaceholder: "請輸入您的電子郵件地址",
          messagePlaceholder: "請在此輸入您的訊息...",
          responseTime: "我們通常在24小時內回覆。",
          "Event not found": "找不到活動",
          "Registration has not started yet": "報名尚未開始",
          "Invalid token": "無效的令牌",
          "Server Error": "伺服器錯誤",
          "alreadyRegisteredForEvent": "已經報名過此活動",
          "emailAlreadyExists": "此電子郵件已被註冊",
          "usernameAlreadyExists": "此用戶名已被使用",
          userProfile: '用戶資料',
          changePassword: '更改密碼',
          currentPassword: '目前密碼',
          confirmNewPassword: '確認新密碼',
          saveChanges: '儲存更改',
          profileUpdateSuccess: '資料更新成功',
          invalidCurrentPassword: '目前密碼不正確',
          profile: '個人資料',
          basicInformation: '基本資料',
          saveBasicInfo: '儲存變更',
          updatePassword: '更新密碼',
          passwordUpdateSuccess: '密碼更新成功',
          currentEmail: '目前電子郵件',
          newEmail: '新電子郵件',
          changeEmail: '更改電子郵件',
          sendVerification: '發送驗證郵件',
          emailVerificationSent: '驗證郵件已發送。請檢查您的收件箱。',
          emailChangeSuccess: '電子郵件更新成功',
          usernameAlreadyTaken: '用戶名 "{{username}}" 已被使用',
        },
      },
    },
    lng: 'zh',
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
