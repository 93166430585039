import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Register from './pages/Register';
import Login from './pages/Login';
import EventDetails from './pages/EventDetails';
import CreateEvent from './pages/CreateEvent';
import AdminPanel from './pages/AdminPanel';
import VerifyEmail from './components/VerifyEmail';
import ErrorBoundary from './components/ErrorBoundary';
import './i18n';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ContactUs from './pages/ContactUs';
import UserProfile from './pages/UserProfile';

function App() {
  return (
    <Router>
      <div className="container">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/event/:id">
            <ErrorBoundary>
              <EventDetails />
            </ErrorBoundary>
          </Route>
          <Route path="/create-event" component={CreateEvent} />
          <Route path="/admin" component={AdminPanel} />
          <Route path="/verify-email/:token" component={VerifyEmail} />
          <Route path="/contact" component={ContactUs} />
          <Route path="/profile" component={UserProfile} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;