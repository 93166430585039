const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const retryOperation = async (operation, maxAttempts = 5, baseDelay = 300) => {
  let lastError;
  
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error;
      
      // If it's not a 500 error, don't retry
      if (error.response && error.response.status !== 500) {
        throw error;
      }
      
      // Calculate delay with exponential backoff
      const delay = baseDelay * Math.pow(2, attempt);
      const jitter = Math.random() * 1000; // Add randomness to prevent thundering herd
      
      console.log(`Attempt ${attempt + 1} failed, retrying in ${delay}ms...`);
      await wait(delay + jitter);
    }
  }
  
  throw lastError;
};
